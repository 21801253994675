if (navigator.serviceWorker) {
  let port = location.port,
      themePath = document.documentElement.dataset.themepath,
      version = document.documentElement.dataset.version;
  if (port == '' || port == 80 || port == 443) {
    port = "";
  } else {
    port = `:${port}`
  }
  let contextPath = document.getElementsByTagName('HTML')[0].getAttribute("data-context");
  let localDocroot = `${location.protocol}//${location.hostname}${port}${contextPath}`;
  // Use the window load event to keep the page load performant
  window.addEventListener('load', function () {
    let alreadyRegistered = false;
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(reg => {
        if (reg && reg.active && reg.active.scriptURL != `${localDocroot}${themePath}/service-worker.js`){
          console.log("[Workbox] Old service worker removed");
          reg.unregister();
        }else{
          alreadyRegistered = true;
        }
      });
      if(!alreadyRegistered){
        // Dopo la disinstallazione, registra un nuovo Service Worker
        navigator.serviceWorker.register(`${localDocroot}${themePath}/service-worker.js`, {scope: `${themePath}/`}
        ).then(function (registration) {
          console.debug('[Workbox] Service worker registration successful, scope is:', registration.scope);
        }).catch(function (error) {
          console.log('[Workbox] Service worker registration failed, error:', error.stack);
        });
      }else{
        console.log("[Workbox] service worker already registered");
      }
    }).catch(error => console.error('[Workbox] Service worker registration logic failed, error:', error.stack));
  });
}
